export const skillsSummary = [
  "C++",
  "Go",
  "SQL",
  "NoSQL",
  "Python",
  "TypeScript",
  "Nodejs",
  "React",
  "Angular",
  "REST",
  "GraphQL",
  "Django",
  "Docker",
  "Cloud Computing Platforms",
  "System Design",
];
