export const companyKeywords: {
  [key: string]: { jobTitle: string; defaultKeywords: string[] };
} = {
  altis: {
    jobTitle: "Senior Software Engineer",
    defaultKeywords: [
      "cloudcomputing",
      "fullstack",
      "leadership",
      "projectmanagement",
      "softwarearchitecture",
      "systemdesign",
      "javascript",
      "reactjs",
      "graphql",
      "datascience",
      "aws",
      "communication",
      "distributedsystems",
      "Networking",
      "programminglanguages",
    ],
  },
  dockermanager: {
    jobTitle: "Manager, Engineer",
    defaultKeywords: [
      "fullstack",
      "leadership",
      "projectmanagement",
      "communication",
      "softwarearchitecture",
      "systemdesign",
      "aws",
      "javascript",
      "react",
      "distributedsystems",
      "c++",
      "teaching",
      "programminglanguages",
    ],
  },
  dockerstaffse: {
    jobTitle: "Staff Software Engineer (Frontend Platform)",
    defaultKeywords: [
      "fullstack",
      "leadership",
      "projectmanagement",
      "testing",
      "communication",
      "softwarearchitecture",
      "systemdesign",
      "aws",
      "javascript",
      "react",
      "distributedsystems",
      "c++",
      "teaching",
      "programminglanguages",
    ],
  },
  fieldeffect: {
    jobTitle: "Senior Software Developer",
    defaultKeywords: [
      "python",
      "database",
      "communication",
      "docker",
      "backend",
      "api",
      "systemdesign",
      "datascience",
      "networking",
      "distributedsystems",
      "networking",
      "testing",
      "leadership",
      "aws",
      "django",
      "fieldeffect",
      "networking",
      "cloud",
    ],
  },
  slackfrontend: {
    jobTitle: "Senior Software Engineer - Frontend",
    defaultKeywords: [
      "fullstack",
      "javascript",
      "websocket",
      "mobileapp",
      "communication",
      "programminglanguages",
    ],
  },
  slackbackend: {
    jobTitle: "Senior Software Engineer - Backend",
    defaultKeywords: [
      "systemdesign",
      "distributedsystems",
      "communication",
      "testing",
      "softwarearchitecture",
      "python",
      "research",
      "leadership",
      "backend",
      "networking",
      "api",
      "graphql",
      "projectmanagement",
      "cloudcomputing",
    ],
  },
  twitch: {
    jobTitle: "Software Development Engineer",
    defaultKeywords: [
      "backend",
      "systemdesign",
      "distributedsystems",
      "communication",
      "testing",
      "softwarearchitecture",
      "python",
      "networking",
      "api",
      "graphql",
      "mobile",
      "distributedsystems",
      "cloudcomputing",
      "aws",
      "programminglanguages",
    ],
  },
  uber: {
    jobTitle: "Software Engineer",
    defaultKeywords: [
      "backend",
      "python",
      "api",
      "graphql",
      "programminglanguages",
      "aws",
      "distributedsystems",
      "cloudcomputing",
      "networking",
      "softwarearchitecture",
      "testing",
      "systemdesign",
      "c++",
      "communication",
    ],
  },
  wealthsimple: {
    jobTitle: "Senior Fullstack Software Engineer - Wealthsimple Tax",
    defaultKeywords: [
      "fullstack",
      "backend",
      "python",
      "javascript",
      "react",
      "redux",
      "django",
      "python",
      "django",
      "api",
      "graphql",
      "aws",
      "distributedsystems",
      "miroservices",
    ],
  },
};
