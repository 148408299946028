export const qualifications = [
  {
    title: "Programming Languages",
    company: "",
    location: "",
    date: "",
    keywords: ["*"],
    highlight: [],
    bulletPoints: [
      // {
      //   text: "Strong C++ coding ability backed by experience in implementing C++ libraries for mathematical computation at the University of Waterloo's research lab, and developing database software with C++ at SAP.",
      //   keywords: ["C++"],
      // },
      // {
      //   text: "Highly experienced in Python, using it daily as a backend langauge and for scripting when developing software.",
      //   keywords: ["Python"],
      // },
      {
        text: "Strong JavaScript/TypeScript coding ability backed by experience in leading software development at KaleidoDeal, and developing code editing software at the University of Waterloo.",
        keywords: ["*"],
      },
      {
        text: "Experienced in Swfit and Dart for developing mobile applications, gained from work experience and personal projects.",
        keywords: ["*"],
      },
    ],
  },
  {
    title: "Platforms and Frameworks",
    company: "",
    location: "",
    date: "",
    keywords: ["*"],
    highlight: [],
    bulletPoints: [
      {
        text: "Expertise in React and Node.js for web and server application development, supported by previous work experience and project experience.",
        keywords: ["*"],
      },
      {
        text: "Experienced in full-stack development, including implementing REST/GraphQL APIs, and working with cloud platforms such as AWS.",
        keywords: ["*"],
      },
      // {
      //   text: "Familiarity with machine learning concepts and tools such as PyTorch, Scikit-learn, Numpy, models such as KNN, SVM, decision trees and neural networks.",
      //   keywords: ["*"],
      // },
    ],
  },
  {
    title: "System and Architecture Design",
    company: "",
    location: "",
    date: "",
    keywords: ["*"],
    highlight: [],
    bulletPoints: [
      {
        text: "Experienced in System Design, including the design of APIs, usage of databases, load balancing, and cloud platforms such as AWS, backed by previous work experience and project experience.",
        keywords: ["*"],
      },
      {
        text: "Proficient in designing and implementing concurrent, multi-threaded, and multi-process software systems. Extensive experience in building software involving locks, job queues, and asynchronous tasks.",
        keywords: ["*"],
      },
    ],
  },
];
